<template>
    <div>
        <v-popover
            v-if="!isDefaultTopic"
            placement="top-end"
            popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
            hide-on-target-click
        >
            <app-button
                type="no-background only-icon"
                icon="ellipsis"
            />

            <template slot="popover">
                <div v-close-popover>
                    <app-button
                        icon="trash"
                        type="link no-padding small"
                        @click="showDeleteModal"
                    >
                        {{ $t("topic_delete") }}
                    </app-button>
                </div>
            </template>
        </v-popover>
        <div v-else>
            <app-button
                v-tooltip.bottom="$t('topics_default_not_deletable')"
                type="no-background only-icon"
                icon="ellipsis"
                color="var(--medium-contrast)"
            />
        </div>
    </div>
</template>

<script>
import { defaultTopicUidPrefix } from "@backend/topic/default-topic";

export default {
    name: "TopicPopupMenu",
    props: {
        /** @type {Topic} */
        topic: { type: Object, required: true },
    },
    computed: {
        isDefaultTopic() {
            return this.topic.uid.startsWith(defaultTopicUidPrefix);
        }
    },
    methods: {
        showDeleteModal() {
            this.$modal.show("confirm-modal", {
                title: this.$t("topic_delete_title", [this.topic.name]),
                text: this.$t("topic_delete_text", [this.topic.name]),
                confirmText: this.$t("topic_delete_title", [this.topic.name]),
                warning: true,
                callback: (confirmed) => {
                    if (confirmed) {
                        this.triggerDelete();
                    }
                },
            });
        },
        triggerDelete() {
            this.$emit("deleteTopic");
        }
    },
};
</script>
