<template>
    <table>
        <thead>
            <tr>
                <th>{{ $t("topics_name") }}</th>
                <th>{{ $t("followed_by") }}</th>
                <th>{{ $t("topics_used") }}</th>
                <th>{{ $t("creation_date") }}</th>
                <th>{{ $t("last_used") }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="topic in topics"
                :key="topic.uid"
                class="border-bottom-low-contrast"
                :class="{deleting: deletingUids.includes(topic.uid)}"
            >
                <td>
                    <loading-topic-label
                        :topic-uid="topic.uid"
                        :name="topic.name"
                        @subscribe="topic.subscriptionCount++"
                        @unsubscribe="topic.subscriptionCount = Math.max(topic.subscriptionCount-1, 0)"
                    />
                </td>
                <td>{{ getCount(topic) }}</td>
                <td>{{ topic.usageCount }}</td>
                <td>{{ formatDate(topic.creationDate) }}</td>
                <td>{{ formatDate(topic.lastModified) }}</td>
                <td>
                    <slot
                        name="actions"
                        :topic="topic"
                    ></slot>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { formatDate } from "@web/lib/time-utils";
import LoadingTopicLabel from "@web/components/topics/LoadingTopicLabel";
import { mapGetters } from "vuex";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import { GET_TOPIC_UIDS } from "@web/store/topic-subscription/getters";

export default {
    name: "TopicsTable",
    components: {
        LoadingTopicLabel,
    },
    props: {
        /** @type {Topic[]} */
        topics: { type: Array, required: true },
        /** @type {string[]} */
        deletingUids: { type: Array, default: [] },
    },
    computed: {
        ...mapGetters({
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
        }),
    },
    methods: {
        formatDate,
        getCount(topic) {
            // This would be the case, if index is out of sync
            if (topic.subscriptionCount === 0 && this.subscribedTopicUids.includes(topic.uid)) return 1;
            return topic.subscriptionCount;
        },
    },
};
</script>

<style lang="scss">
thead {
    text-align: left;
    border-bottom: 2px solid $low-contrast;

    th {
        padding-bottom: $spacing-xsmall;
    }
}

td {
    padding: $spacing-small 0;
}

.deleting {
    color: $lowest-contrast;
}
</style>
