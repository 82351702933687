export const defaultTopicUidPrefix = "default-topic-";

export function defaultTopicUid(intranetUid: string): string {
    return defaultTopicUidPrefix + intranetUid;
}

export function ensureDefaultTopicInEmptyTopics(intranetUid: string, topicUids?: string[]): string[] {
    if (!topicUids?.length) {
        return [defaultTopicUid(intranetUid)];
    }
    return topicUids;
}
