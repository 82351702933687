<template>
    <div class="page">
        <div class="container padded">
            <page-header
                :title="$t('topics_overview')"
                class="mbottom-medium"
            />
            <div class="grid v-centered mbottom-medium">
                <div>
                    <search-input
                        v-model.trim="query"
                        :placeholder="$t('topics_search')"
                        @input="debouncedStartSearch"
                    />
                </div>
                <div>
                    <app-button
                        v-if="isAdmin"
                        icon="plus"
                        type="primary"
                        @click="onAddTopic"
                    >
                        {{ $t("topics_add") }}
                    </app-button>
                </div>
            </div>
            <transition
                name="fade"
                mode="out-in"
            >
                <div v-if="initialLoading">
                    <pu-skeleton
                        class="d-block ptop-xsmall pbottom-xsmall"
                    />
                    <pu-skeleton
                        v-for="i in 3"
                        :key="i"
                        class="d-block ptop-medium pbottom-medium"
                    />
                </div>
                <system-message
                    v-else-if="topicStore.state.hasError"
                    type="unknown"
                />
                <div v-else-if="topicStore.state.topics.length === 0">
                    <system-message
                        v-if="isAdmin && !executedSearch"
                        :button-text="$t('topics_add')"
                        type="results"
                        @click="onAddTopic"
                    />
                    <system-message
                        v-else
                        type="results"
                    />
                </div>
                <div v-else>
                    <topics-table
                        :topics="topicStore.state.topics"
                        :deleting-uids="deletingUids"
                        class="w-100"
                    >
                        <template
                            v-if="isAdmin"
                            #actions="{topic}"
                        >
                            <topic-popup-menu
                                :topic="topic"
                                @deleteTopic="deleteTopic(topic.uid)"
                            />
                        </template>
                    </topics-table>
                    <infinite-scroll-spinner
                        v-if="topicStore.state.hasMore && topicStore.state.initiallyLoaded"
                        :loading="topicStore.state.fetching"
                        @load-more="loadMore()"
                    />
                </div>
            </transition>
        </div>
        <create-topic-modal
            :name="createTopicModalName"
            :topic-store="topicStore"
        />
    </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET_UID,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import {
    searchTopics,
    useTopics,
} from "@web/store/topic/topic-local-store";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import { GET_TOPIC_UIDS } from "@web/store/topic-subscription/getters";
import PageHeader from "@web/components/PageHeader";
import CreateTopicModal from "@web/components/topics/CreateTopicModal";
import TopicsTable from "@web/components/topics/TopicsTable";
import TopicPopupMenu from "@web/components/topics/TopicPopupMenu";
import SearchInput from "@web/components/content-search/SearchInput";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";

const createTopicModalName = "create-topic-modal";

export default {
    name: "TopicsRoute",
    components: {
        InfiniteScrollSpinner,
        SearchInput,
        TopicPopupMenu,
        TopicsTable,
        CreateTopicModal,
        PageHeader,
    },
    data() {
        return {
            deletingUids: [],
            createTopicModalName,
            query: "",
        };
    },
    computed: {
        ...mapGetters({
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
        }),
        executedSearch() {
            return this.query.length > 0;
        },
        topicStore() {
            return useTopics(this.$store);
        },
        initialLoading() {
            return !this.topicStore.state.initiallyLoaded && this.topicStore.state.fetching;
        },
    },
    mounted() {
        this.fetchTopics({ page: 0 });
    },
    created() {
        // The debounce function MUST be created once per instance. Otherwise the closure state of the debounce function is shared between all instances of the Vue component.
        this.debouncedStartSearch = debounce(this.startSearch, 200);
    },
    methods: {
        onAddTopic() {
            this.$modal.show(createTopicModalName);
        },
        async deleteTopic(topicUid) {
            try {
                this.deletingUids.push(topicUid);
                await this.topicStore.methods.deleteTopic({ topicUid });
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    title: this.$t("unknown_error"),
                    text: this.$t("unknown_error_text"),
                });
            } finally {
                this.deletingUids = this.deletingUids.filter(uid => uid !== topicUid);
            }
        },
        fetchTopics({ page, query = "" } = {}) {
            this.topicStore.methods.fetchTopics(() => searchTopics({ page, query }));
        },
        startSearch() {
            this.fetchTopics({ page: 0, query: this.query });
        },
        debouncedStartSearch() {
            // placeholder for IDE support, overwritten in created to be debounced per instance.
        },
        loadMore() {
            this.fetchTopics({ query: this.query });
        },
    },
};
</script>
